import React from "react"
import { Parallax } from "react-spring/renderprops-addons.cjs"
import Layout from "@lekoarts/gatsby-theme-cara/src/components/layout"
import Inner from "@lekoarts/gatsby-theme-cara/src/elements/inner"
import Content from "@lekoarts/gatsby-theme-cara/src/elements/content"
import Divider from "@lekoarts/gatsby-theme-cara/src/elements/divider"
import SVG from "@lekoarts/gatsby-theme-cara/src/components/svg"
import { UpDown, UpDownWide } from "@lekoarts/gatsby-theme-cara/src/styles/animations"
import Footer from "@lekoarts/gatsby-theme-cara/src/components/footer"

const NotFound = () => {
  return (
    <Layout>
      <Parallax pages={1}>
      <div>
        <Divider
          bg="divider"
          clipPath="polygon(0 16%, 100% 4%, 100% 82%, 0 94%)"
          speed={0.2}
          offset={0}
          factor={1}
        />
         <Divider speed={0.1} offset={0} factor={1}>
          <UpDown>
            <SVG icon="box" hiddenMobile width={6} color="icon_blue" left="50%" top="75%" />
            <SVG icon="upDown" hiddenMobile width={8} color="icon_darkest" left="70%" top="20%" />
            <SVG icon="triangle" width={8} stroke color="icon_darkest" left="25%" top="5%" />
            <SVG icon="upDown" hiddenMobile width={24} color="icon_orange" left="80%" top="80%" />
          </UpDown>
          <UpDownWide>
            <SVG icon="arrowUp" hiddenMobile width={16} color="icon_purple" left="5%" top="80%" />
            <SVG icon="triangle" width={12} stroke color="icon_brightest" left="95%" top="50%" />
            <SVG icon="circle" hiddenMobile width={6} color="icon_brightest" left="85%" top="15%" />
            <SVG icon="upDown" hiddenMobile width={8} color="icon_darkest" left="45%" top="10%" />
          </UpDownWide>
          <SVG icon="circle" hiddenMobile width={6} color="icon_brightest" left="4%" top="20%" />
          <SVG icon="circle" width={12} color="icon_darkest" left="70%" top="60%" />
          <SVG icon="box" width={6} color="icon_orange" left="10%" top="10%" />
          <SVG icon="box" width={12} color="icon_darkest" left="20%" top="30%" />
          <SVG icon="hexa" width={8} stroke color="icon_darkest" left="80%" top="70%" />
        </Divider>
        <Content speed={0.4} offset={0} factor={1}>
            <Inner>
                <h1>Well this is embarrassing</h1>
                <p>Looks like you're looking for a page that doesn't exist.</p>
                <p>Return to our home page <a href="/">here</a> to find what you're looking for.</p>
            </Inner>
            <Footer />
        </Content>
        </div>
      </Parallax>
    </Layout>
  )
}

export default NotFound